import React from 'react'
// import Layout from '../components/layout'
// import SEO from '../components/seo'
import TransitionLink from 'gatsby-plugin-transition-link'

function ContactPage() {
  return <a href="mailto:jjd@johndore.com"></a>
}

export default ContactPage
